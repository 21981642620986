@import '../variables.scss';

.select-inline {
  position: relative;
  visibility: hidden;
  transition: $transit-norm;
  opacity: 0;
  border: 1px solid $gray-1;
  border-radius: 4px;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &.small {
    .header {
      padding: 6px 14px;
    }

    .options {
      li {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 6px 14px;
      }
    }
  }

  .header {
    padding: 12px 19px;
    background-color: $gray-2;
    color: white;
    font-weight: 500;
  }

  .options {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: none;
    width: 100%;
    background-color: white;
    overflow: hidden;
    z-index: $z-modal;

    li {
      cursor: pointer;
      color: $gray-800;
      font-size: 14px;
      line-height: 1;
      padding: 12px 19px;

      &:hover {
        color: white;
        background-color: $primary;
      }
    }
  }
}
