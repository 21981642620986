@import '../variables.scss';

.gallery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: $gap-medium;
  overflow: hidden;
  align-items: flex-start;
}

@media (max-width: $breakpoint-md) {
  .gallery {
    justify-content: center;
  }
}
