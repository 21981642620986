@import '../variables.scss';

.card {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .card-top {
    padding: 20px 30px;
    background: $gray-2;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
      color: white;
    }

    .right-button {
      margin: -8px -16px;
      padding: 8px 16px;
    }
  }

  .card-body {
    position: relative;
    background: white;
    padding: 40px 25px;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: $breakpoint-sm) {
  .card {
    .card-body {
      padding: 30px 15px;
    }
  }
}
