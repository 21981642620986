@import '../variables.scss';

.login-card {
  width: 560px;
  max-width: 100%;

  .login-form {
    display: flex;
    flex-direction: column;
    row-gap: $gap-small;

    .signin-btn {
      margin-top: $gap-small;
    }
  }
}
