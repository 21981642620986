@import '../variables.scss';

.payments-list {
  display: flex;
  flex-direction: column;
  row-gap: $gap-small;
  overflow-y: hidden;
  overflow-x: hidden;

  .header {
    display: flex;
    column-gap: $gap-small;

    .col-1,
    .col-2,
    .col-12,
    .col-3,
    .col-4 {
      flex-basis: 0;
      flex-shrink: 0;
    }

    .col-1 {
      flex-grow: 1;
    }

    .col-2 {
      flex-grow: 2;
    }

    .col-12 {
      display: none;
      flex-grow: 2;
    }

    .col-3 {
      flex-grow: 3;
    }

    .col-4 {
      flex-grow: 1;
      text-align: right;
    }
  }

  .payment {
    display: flex;
    align-items: center;
    column-gap: $gap-small;

    .col-1 {
      flex-grow: 1;
    }

    .col-2 {
      flex-grow: 2;
      word-break: break-all;
    }

    .col-12 {
      display: none;
      flex-grow: 2;
      flex-direction: column;
      gap: $gap-xsmall;
    }

    .col-3 {
      flex-grow: 3;
    }

    .col-4 {
      flex-grow: 1;
      text-align: right;
    }

    .col-1,
    .col-2,
    .col-12,
    .col-3,
    .col-4 {
      flex-basis: 0;
      flex-shrink: 0;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .payments-list {
    .header {
      .col-3 {
        flex-grow: 2;
      }
    }

    .payment {
      .col-3 {
        flex-grow: 2;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .payments-list {
    .header {
      > .col-1,
      > .col-2 {
        display: none;
      }

      .col-12 {
        display: block;
      }
    }

    .payment {
      > .col-1,
      > .col-2 {
        display: none;
      }

      .col-12 {
        display: flex;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .payments-list {
    .header {
      column-gap: $gap-xsmall;
    }

    .payment {
      column-gap: $gap-xsmall;
    }
  }
}
