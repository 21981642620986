@import '../variables.scss';

.team-card {
  .coach-wrap {
    display: flex;
    gap: $gap-xxsmall;

    .coach-list {
      margin: -2px 0;
      display: flex;
      column-gap: $gap-xsmall;
      flex-wrap: wrap;

      .coach-item {
        display: flex;
        align-items: center;

        button {
          padding: 2px 8px;
          margin-top: -2px;
        }
      }
    }
  }

  .details {
    .buttons {
      margin-top: $gap-xsmall;
      display: flex;
      column-gap: $gap-small;
      row-gap: $gap-xsmall;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}
