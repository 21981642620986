@import '../variables.scss';

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap-small;

  .tabs-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid $gray-1;
    color: $gray-500;

    .tabs-header-item {
      flex-grow: 1;
      position: relative;
      padding: 18px 32px;
      transition: $transit-norm;
      letter-spacing: 0.1em;
      cursor: pointer;
      border-left: none;
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 50%;
        display: block;
        width: 7px;
        height: 7px;
        background: $primary;
        opacity: 0;
        transform: rotate(45deg) translateX(-50%);
        transition: $transit-norm;
        pointer-events: none;
      }

      &:hover {
        color: white;
        background-color: $primary;
      }

      &.active {
        color: white;
        background-color: $primary;

        &::before {
          bottom: -6px;
          opacity: 1;
        }
      }
    }
  }

  > :last-child {
    flex-grow: 1;
  }
}
