@import '../variables.scss';

.invite-card {
  width: 560px;
  max-width: 100%;

  .card-body {
    row-gap: $gap-small;

    > .error-msg {
      color: $danger;
      max-height: 0;
      opacity: 0;
      font-size: 16px;
      transition: $transit-norm;
      text-align: center;

      &.show {
        max-height: 20px;
        opacity: 1;
      }
    }

    .btns-wrap {
      display: flex;
      justify-content: space-between;

      &:has(:only-child) {
        justify-content: center;
      }
    }
  }
}
