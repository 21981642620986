@import '../variables.scss';

.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .layout-header {
    min-height: 64px;
    padding: $gap-xsmall 0;
    color: white;
    background-color: $dark-midnight-blue;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $gap-xxsmall;
    }
  }

  &.screen-sized {
    max-height: 100vh;
    overflow: hidden;

    .layout-body {
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .layout-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    padding: $gap-medium 0;

    .container {
      flex-grow: 1;
      height: unset;
    }
  }
}
