@import '../variables.scss';

.tournaments-list {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: $gap-small;
  overflow-y: hidden;
  overflow-x: hidden;

  .controls {
    display: flex;
    justify-content: space-between;
    gap: $gap-small;
    flex-wrap: wrap;
    border-bottom: 1px solid $gray-200;
    padding-bottom: $gap-small;

    .filters {
      display: flex;
      gap: $gap-small;
      flex-wrap: wrap;
      list-style-type: none;

      .location,
      .sport,
      .time {
        width: 180px;
      }
    }
  }

  .header {
    display: flex;
    column-gap: $gap-small;

    .col-1 {
      width: 64px;
      flex-shrink: 0;
    }

    .col-2 {
      width: 15%;
      flex-shrink: 0;
    }

    .col-3 {
      width: 20%;
      flex-shrink: 0;
    }

    .col-4 {
      width: 20%;
      flex-shrink: 0;
    }

    .col-6 {
      flex-grow: 1;
      text-align: right;
    }

    .col-23-wrap,
    .col-45-wrap,
    .col-456-wrap {
      display: none;
    }

    .additional {
      width: 20%;
      flex-shrink: 0;
      display: flex;
      column-gap: $gap-small;

      .status {
        width: 70%;
        text-align: center;
      }

      .paid {
        width: 30%;
        text-align: right;
      }

      .status-paid-wrap {
        display: none;
      }
    }
  }

  .tournament {
    display: flex;
    align-items: center;
    column-gap: $gap-small;

    .bg-image {
      flex-shrink: 0;
    }

    .col-23-wrap {
      display: none;
    }

    .col-2 {
      width: 15%;
      flex-shrink: 0;
      transition: $transit-norm;
    }

    .col-3 {
      width: 20%;
      flex-shrink: 0;
      transition: $transit-norm;
    }

    .col-4 {
      width: 20%;
      flex-shrink: 0;
      transition: $transit-norm;
      white-space: pre-line;
    }

    .col-4-8-wrap {
      width: 30%;

      .col-4,
      .col-78 {
        display: none;
      }
    }

    .col-5,
    .col-6 {
      transition: $transit-norm;
    }

    .col-78-wrap {
      flex-grow: 1;
      flex-shrink: 0;
      text-align: right;
    }

    .col-7,
    .col-8,
    .col-78 {
      transition: $transit-norm;
    }

    .team-tourn-status-paid {
      width: 20%;
    }

    &:hover {
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-78,
      .paid {
        color: $primary;
      }
    }
  }

  &.add-col {
    .header {
      .col-3 {
        width: 16%;
      }

      .col-4 {
        width: 14%;
      }
    }

    .tournament {
      .col-3 {
        width: 16%;
      }

      .col-4 {
        width: 14%;
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .tournaments-list {
    .header {
      > .col-2,
      > .col-3,
      > .col-4,
      > .col-5 {
        display: none;
      }

      .col-23-wrap,
      .col-45-wrap {
        display: block;
      }

      .col-23-wrap {
        width: 32%;
        flex-shrink: 0;
      }
    }

    .tournament {
      > .col-2,
      > .col-3,
      > .col-4 {
        display: none;
      }

      .col-23-wrap {
        width: 32%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        .col-2,
        .col-3 {
          width: unset;
        }

        .col-3 {
          text-align: left;
        }
      }

      .col-4-8-wrap {
        .col-4 {
          display: block;
          width: unset;
        }
      }
    }

    &.add-col {
      .header {
        .col-23-wrap {
          width: 26%;
        }
      }

      .tournament {
        .col-23-wrap {
          width: 26%;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .tournaments-list {
    .header {
      .additional {
        .status,
        .paid {
          display: none;
        }

        .status-paid-wrap {
          display: flex;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .tournaments-list {
    .header {
      .col-1 {
        width: 32px;
      }
    }

    .tournament {
      .wh-64 {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .tournaments-list {
    .header {
      .col-45-wrap,
      .col-6 {
        display: none;
      }

      .col-23-wrap {
        width: 30%;
      }

      .col-456-wrap {
        display: block;
      }
    }

    .tournament {
      .col-23-wrap {
        width: 30%;
      }

      .col-4-8-wrap {
        width: unset;
        flex-grow: 1;

        .col-78 {
          display: block;
        }
      }

      .col-78-wrap {
        display: none;
      }
    }
  }
}
