@import '../variables.scss';

.check-box {
  display: flex;
  gap: $gap-xsmall;
  padding: 12px 19px;

  &.small {
    padding: 6px 14px;

    .label {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid $gray-1;

    .fa-check::before {
      color: $primary;
      font-size: 14px;
      opacity: 0;
      transition: $transit-norm;
    }

    .fa-check.visible::before {
      opacity: 1;
    }
  }
}
