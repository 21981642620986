@import '../variables.scss';

.team-card-edit {
  .select-wrap {
    display: flex;
    gap: $gap-small;

    > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .team-card-edit {
    .select-wrap {
      flex-direction: column;
      gap: $gap-small;
    }
  }
}
