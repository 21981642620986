// Colors
$gray-1: #e1e1e1;
$gray-2: #35363c;
$gray-3: #c6c6c6;
$gray-100: #edeff4;
$gray-200: #d7d7d7;
$gray-500: #9b9b9b;
$gray-800: #151515;

$red: #fd6565;
$red-2: #ff4c39;
$dark-midnight-blue: #16324d;

$primary: #3f90dc;
$secondary: #f94f4f;
$danger: $red;
$success: #029a9a;

// Sizes and spaces
$gap-xxsmall: 4px;
$gap-xsmall: 8px;
$gap-small: 16px;
$gap-medium: 32px;
$gap-big: 64px;

// Transitions
$transit-norm: 300ms;

// Shadows
$shadow-norm: 0 7px 18px rgba(0, 0, 0, 0.13);

// Z-indexes
$z-modal: 100;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

// Container max width
$container-max-widths-sm: 540px;
$container-max-widths-md: 720px;
$container-max-widths-lg: 960px;
$container-max-widths-xl: 1140px;
$container-max-widths-xxl: 1520px;
