@import '../variables.scss';

.payment-modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $gap-small;

  .amount-pay-wrap {
    display: flex;
    gap: $gap-small;

    .input {
      flex-grow: 1;
    }
  }

  button {
    align-self: center;
  }

  .err-msg-wrap {
    max-height: 0;
    overflow: hidden;
    transition: $transit-norm;
    text-align: center;

    &.show {
      max-height: 20px;
    }
  }

  .pay-link-wrap {
    max-height: 0;
    overflow: hidden;
    transition: $transit-norm;

    &.show {
      max-height: 200px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .payment-modal-body {
    .amount-pay-wrap {
      flex-direction: column;
    }

    .pay-link-wrap {
      &.show {
        max-height: 280px;
      }
    }
  }
}
