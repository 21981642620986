@import '../variables.scss';

.input {
  position: relative;

  input {
    width: 100%;

    &.error {
      border-color: $danger;
    }
  }

  .error-msg {
    position: absolute;
    top: 2px;
    right: 4px;
    opacity: 0;
    visibility: hidden;
    transition: $transit-norm;
    font-size: 12px;
    color: $danger;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
