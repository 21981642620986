@import '../variables.scss';

.tournament-card-edit {
  .sports-dates {
    display: flex;
    align-items: flex-start;
    gap: $gap-small;

    .sports {
      flex-grow: 1;
    }

    .dates {
      display: flex;
      flex-shrink: 0;
      gap: $gap-small;

      .end-date {
        .react-date-picker__calendar {
          right: 0px !important;
          left: auto !important;
        }
      }
    }
  }

  .division {
    display: flex;
    gap: $gap-small;

    .genders {
      flex-grow: 1;
    }

    .ages {
      display: flex;
      gap: $gap-small;

      .age-from,
      .age-to {
        min-width: 188px;
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .tournament-card-edit {
    .sports-dates {
      flex-direction: column;
      align-items: unset;

      .dates {
        .begin-date,
        .end-date {
          flex-grow: 1;
        }
      }
    }

    .division {
      .ages {
        .age-from,
        .age-to {
          min-width: 120px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .tournament-card-edit {
    .sports-dates {
      flex-direction: column;
    }

    .division {
      flex-direction: column;

      .ages {
        .age-from,
        .age-to {
          min-width: unset;
          flex-grow: 1;
          flex-basis: 0;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .tournament-card-edit {
    .sports-dates {
      .dates {
        flex-direction: column;
      }
    }
  }
}
