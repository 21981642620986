@import '../variables.scss';

.none-card-edit {
  .contacts-wrap {
    display: flex;
    justify-content: space-between;
    gap: $gap-small;

    > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .none-card-edit {
    .contacts-wrap {
      flex-direction: column;
    }
  }
}
