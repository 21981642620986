@import '../variables.scss';

.player-card {
  .props-checklist-wrap {
    display: flex;
    gap: $gap-big;

    .team-wrap {
      p {
        display: inline;
      }
    }
  }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  .player-card {
    .props-checklist-wrap {
      gap: $gap-medium;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .player-card {
    .props-checklist-wrap {
      justify-content: space-between;
      gap: $gap-xsmall;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .player-card {
    .props-checklist-wrap {
      flex-direction: column;
    }
  }
}
