@import '../variables.scss';

.card-edit-template {
  position: relative;
  display: flex;
  gap: $gap-small;
  width: 100%;

  .image-btns {
    align-self: flex-start;
  }

  .children-btns {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: $gap-xsmall;

    .children-wrap {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: $gap-small;
    }

    .request-error {
      color: $danger;
      text-align: center;
      overflow: hidden;
      max-height: 0;
      transition: $transit-norm;

      &.show {
        max-height: 20px;
      }
    }

    .save-cancel-btns {
      display: flex;
      justify-content: center;
      gap: $gap-small;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .card-edit-template {
    flex-direction: column;
    align-items: center;

    .image-btns {
      align-self: unset;
    }

    .children-btns {
      width: 100%;
    }
  }
}
