@import '../variables.scss';

.user {
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  gap: $gap-xsmall;
  align-items: center;
  position: relative;

  .icon {
    font-family: 'FontAwesome';
    font-size: 16px;
    color: $primary;
  }

  .name {
    color: white;
  }

  .context-menu {
    left: unset;
    right: 0;
  }
}
