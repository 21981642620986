@import '../variables.scss';

.team-tourn-status-paid {
  display: flex;
  align-items: center;
  gap: $gap-xsmall;
  flex-shrink: 0;

  .status {
    width: 70%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: $gap-xsmall;

    &.invited {
      flex-direction: column;
      align-items: center;

      p {
        text-align: center;
      }

      .btn-wrap {
        display: flex;
        gap: $gap-xsmall;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .paid {
    width: 30%;
    gap: $gap-xsmall;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    transition: $transit-norm;
    display: none;

    &.show {
      display: flex;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .team-tourn-status-paid {
    flex-direction: column;

    .status,
    .paid {
      width: unset;
    }

    .paid {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
