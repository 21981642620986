@import '../variables.scss';

.scroll-to-top-button {
  position: fixed;
  right: $gap-medium;
  bottom: $gap-medium;
  z-index: $z-modal;
  font-family: 'FontAwesome';
  font-size: 18px;
  padding: 0;
  width: 50px;
  height: 50px;
  min-height: unset;
  transform: translateY(50px + $gap-medium);

  &:hover {
    color: white;
    background-color: $gray-2;
    border-color: $gray-2;
  }

  &.show {
    transform: unset;
  }
}

@media (max-width: $breakpoint-sm) {
  .scroll-to-top-button {
    right: $gap-small;
    bottom: $gap-small;
  }
}
