@import '../variables.scss';

.payment-success-card {
  width: 560px;
  max-width: 100%;

  .card-body {
    row-gap: $gap-medium;

    button {
      align-self: center;
    }
  }
}
