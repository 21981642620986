@import '../variables.scss';

.info-modal {
  .info-card {
    width: 560px;
    max-width: 100%;

    .btn-block {
      margin-top: $gap-medium;
      display: flex;
      justify-content: space-between;

      &:has(:only-child) {
        justify-content: center;
      }
    }
  }
}
