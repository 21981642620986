@import '../variables.scss';

.teams-list {
  display: flex;
  flex-direction: column;
  gap: $gap-small;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  .controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $gap-small;
    border-bottom: 1px solid $gray-200;
    padding-bottom: $gap-small;

    .filters {
      display: flex;
      gap: $gap-small;
      flex-wrap: wrap;
      list-style-type: none;

      .location,
      .sport {
        width: 180px;
      }
    }
  }

  .header {
    display: flex;
    column-gap: $gap-small;
    align-items: center;

    .col-1 {
      width: 64px;
      flex-shrink: 0;
    }

    .col-2 {
      width: 16%;
      flex-shrink: 0;
    }

    .col-3 {
      width: 20%;
      flex-shrink: 0;
    }

    .col-4 {
      width: 20%;
      flex-shrink: 0;
    }

    .col-5 {
      width: 11%;
      flex-shrink: 0;
    }

    .col-6 {
      flex-grow: 1;
      flex-shrink: 0;
      text-align: right;
    }

    .col-23,
    .col-456 {
      display: none;
    }

    .additional {
      width: 20%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      column-gap: $gap-small;

      .status {
        width: 70%;
        text-align: center;
      }

      .paid {
        width: 30%;
        text-align: right;
      }

      .status-paid-wrap {
        display: none;
      }
    }
  }

  .team {
    display: flex;
    align-items: center;
    gap: $gap-small;

    .bg-image {
      flex-shrink: 0;
    }

    .col-2 {
      width: 16%;
      transition: $transit-norm;
      flex-shrink: 0;
    }

    .col-3 {
      width: 20%;
      flex-shrink: 0;
      transition: $transit-norm;
      overflow-wrap: break-word;
    }

    .col-4 {
      width: 20%;
      flex-shrink: 0;
      transition: $transit-norm;
    }

    .col-5 {
      width: 11%;
      flex-shrink: 0;
      transition: $transit-norm;
    }

    .col-6 {
      flex-grow: 1;
      flex-shrink: 0;
      text-align: right;
      transition: $transit-norm;
    }

    .col-23,
    .col-456 {
      display: none;
      transition: $transit-norm;
    }

    .team-tourn-status-paid {
      width: 20%;
      flex-shrink: 0;

      .paid {
        transition: $transit-norm;
      }
    }

    &:hover {
      &:not(.disabled) {
        .col-2,
        .col-3,
        .col-23,
        .col-4,
        .col-5,
        .col-6,
        .col-456,
        .paid {
          color: $primary;
        }
      }
    }
  }

  &.add-col {
    .header {
      .col-6 {
        text-align: unset;
      }

      .col-3 {
        width: 16%;
      }

      .col-4 {
        width: 14%;
      }
    }

    .team {
      .col-6 {
        text-align: unset;
      }

      .col-3 {
        width: 16%;
      }

      .col-4 {
        width: 14%;
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .teams-list {
    .header {
      .additional {
        width: 14%;
      }
    }
    .team {
      .team-tourn-status-paid {
        width: 14%;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .teams-list {
    .header {
      > .col-2,
      > .col-3,
      > .col-4,
      > .col-5,
      > .col-6 {
        display: none;
      }

      .col-23 {
        display: block;
        width: 30%;
        flex-shrink: 0;
      }

      .col-456 {
        display: block;
      }

      .additional {
        width: 22%;

        .status,
        .paid {
          display: none;
        }

        .status-paid-wrap {
          flex-grow: 1;
          display: block;
          text-align: center;
        }
      }
    }

    .team {
      > .col-2,
      > .col-3,
      > .col-4,
      > .col-5,
      > .col-6 {
        display: none;
      }

      .col-23 {
        display: block;
        width: 30%;
        flex-shrink: 0;

        .col-2,
        .col-3 {
          width: unset;
        }
      }

      .col-456 {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: unset;

        .col-4 {
          width: unset;
        }

        .col-5 {
          width: unset;
          flex-shrink: unset;
          text-align: left;
        }
      }

      .team-tourn-status-paid {
        width: 22%;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .teams-list {
    .header {
      .col-1 {
        width: 32px;
      }

      .col-23 {
        width: 35%;
      }
    }

    .team {
      .wh-64 {
        width: 32px;
        height: 32px;
      }

      .col-23 {
        width: 35%;
      }
    }
  }
}
