@import '../variables.scss';

.image-modal {
  flex-direction: column;

  .bg-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    margin: 40px 0;

    &:hover {
      background-size: contain;
    }
  }

  .close-btn {
    position: absolute;
    top: 34px;
    right: 26px;
    font-size: 36px;
    font-weight: 400;
    font-family: 'FontAwesome';
  }

  .title {
    position: absolute;
    color: white;
    top: 40px;
    text-align: center;
  }

  .download-btn {
    position: absolute;
    bottom: 40px;
  }
}
