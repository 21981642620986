@import '../variables.scss';

.auth-card {
  width: 560px;
  max-width: 100%;

  .auth-form {
    display: flex;
    flex-direction: column;
    gap: $gap-small;

    .invitation {
      display: flex;
      flex-direction: column;
      gap: $gap-small;
    }

    > .error-msg {
      color: $danger;
      max-height: 0;
      opacity: 0;
      font-size: 16px;
      transition: $transit-norm;
      text-align: center;

      &.show {
        max-height: 20px;
        opacity: 1;
      }
    }

    button {
      align-self: center;
    }
  }
}
