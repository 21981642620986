@import '../variables.scss';

.marked-text {
  display: flex;
  gap: $gap-xxsmall;
  align-items: center;

  .mark {
    min-width: 14px;
    font-family: 'FontAwesome';

    &.green {
      color: $success;
    }

    &.red {
      color: $danger;
    }
  }
}
