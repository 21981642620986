@import '../variables.scss';

.card-header-view {
  display: flex;
  align-items: stretch;
  gap: $gap-small;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  transition: $transit-norm;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .info-buttons-wrap {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: column;
      gap: $gap-xsmall;
      margin-top: -8px;
    }

    > .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      button {
        width: fit-content;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .card-header-view {
    flex-direction: column;

    .image-btns {
      align-self: center;
    }

    .info-buttons-wrap {
      flex-direction: column;
      gap: $gap-small;

      .info {
        margin-top: unset;
      }

      > .buttons {
        flex-direction: row;
      }
    }
  }
}
