@import '../variables.scss';

.players-list {
  display: flex;
  flex-direction: column;
  gap: $gap-small;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  .header {
    display: flex;
    column-gap: $gap-small;
    align-items: center;

    .col-1 {
      width: 64px;
      flex-shrink: 0;
    }

    .col-2 {
      width: 25%;
    }

    .col-3 {
      width: 15%;
      text-align: center;
    }

    .col-4 {
      flex-grow: 1;
      text-align: center;
    }

    .col-5 {
      width: 25%;
      text-align: center;
    }

    .col-23 {
      display: none;
      width: 28%;
    }

    .col-234 {
      display: none;
      flex-grow: 1;
    }

    .col-6 {
      width: 80px;
      flex-shrink: 0;
    }
  }

  .player {
    display: flex;
    align-items: center;
    column-gap: $gap-small;

    .col-2,
    .col-3,
    .col-4,
    .col-5 .players-docs-checklist .marked-text .text {
      transition: $transit-norm;
    }

    .bg-image {
      flex-shrink: 0;
    }

    .col-2 {
      width: 25%;
      flex-shrink: 0;

      .dob,
      .card {
        display: none;
      }
    }

    .col-3 {
      width: 15%;
      flex-shrink: 0;
      text-align: center;
    }

    .col-4 {
      flex-grow: 1;
      text-align: center;
    }

    .col-5 {
      width: 25%;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
    }

    .col-6 {
      width: 80px;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: stretch;

      .remove-btn-small {
        display: none;
      }
    }

    &:hover:not(:has(.col-6:hover)) {
      &:not(.disabled) {
        .col-2,
        .col-3,
        .col-4,
        .col-5 .players-docs-checklist .marked-text .text {
          color: $primary;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .players-list {
    .header {
      .col-5 {
        width: 18%;
      }
    }

    .player {
      .col-5 {
        width: 18%;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .players-list {
    .header {
      > .col-2,
      > .col-3 {
        display: none;
      }

      .col-23 {
        display: block;
      }

      .col-5 {
        width: 24%;
      }

      .col-6 {
        width: 42px;
      }
    }

    .player {
      .col-2 {
        width: 28%;

        .dob {
          display: inline;
        }
      }

      .col-3 {
        display: none;
      }

      .col-5 {
        width: 24%;
      }

      .col-6 {
        width: 42px;

        .remove-btn-big {
          display: none;
        }
        .remove-btn-small {
          display: block;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .players-list {
    .header {
      .col-1 {
        width: 32px;
      }

      .col-23 {
        display: none;
      }

      .col-234 {
        display: block;
      }

      .col-4 {
        display: none;
      }

      .col-5 {
        width: 26%;
      }
    }

    .player {
      .col-2 {
        flex-grow: 1;
        flex-shrink: unset;

        .card {
          display: inline;
        }
      }

      .col-4 {
        display: none;
      }

      .col-5 {
        width: 26%;
      }

      .wh-64 {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .players-list {
    .header {
      column-gap: $gap-xsmall;
    }
    .player {
      column-gap: $gap-xsmall;
    }
  }
}
