@import '../variables.scss';

.context-menu {
  list-style-type: none;
  position: absolute;
  top: calc(100% + $gap-xsmall);
  left: 0;
  min-width: 180px;
  background-color: white;
  color: black;
  z-index: $z-modal;
  border: 1px solid $gray-1;
  transition: $transit-norm;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);

  &.show {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  li {
    padding: 12px 25px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: $gray-800;
    border-bottom: 1px solid $gray-1;
    transition: $transit-norm;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: $primary;
    }
  }
}
