@import '../variables.scss';

.player-card-edit {
  .general-info,
  .contacts-wrap,
  .card-wrap {
    display: flex;
    justify-content: space-between;
    gap: $gap-small;

    > * {
      flex-grow: 1;
      flex-basis: 0;
    }

    .birth-date,
    .expire-date {
      .react-date-picker__calendar {
        right: 0px !important;
        left: auto !important;
      }
    }
  }

  .general-info {
    .name {
      flex-grow: 2;
      min-width: 50%;
    }
  }

  .contacts-wrap {
    .phone {
      min-width: 50%;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .player-card-edit {
    .general-info,
    .contacts-wrap,
    .card-wrap {
      flex-direction: column;
    }
  }
}
