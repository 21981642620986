@import '../variables.scss';

.admin-panel {
  min-height: 100%;

  .admin-panel-tabs {
    display: flex;
    overflow: hidden;
    flex-grow: 1;

    .component-wrap {
      display: flex;
      flex-direction: column;

      .tournaments-list,
      .teams-list {
        flex-grow: 1;
      }
    }
  }
}
