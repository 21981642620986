@import '../variables.scss';

.date-picker-wrap {
  position: relative;

  .placeholder-text {
    position: absolute;
    top: 17px;
    left: 19px;
    color: $gray-500;
    visibility: hidden;
  }

  &.placeholder {
    .placeholder-text {
      visibility: visible;
    }

    .react-date-picker__inputGroup {
      visibility: hidden;
    }
  }

  &.error {
    .react-date-picker {
      .react-date-picker__wrapper {
        border-color: $danger;
      }
    }
  }

  .error-msg {
    position: absolute;
    top: 2px;
    right: 4px;
    opacity: 0;
    visibility: hidden;
    transition: $transit-norm;
    font-size: 12px;
    color: $danger;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .react-date-picker {
    width: 100%;

    button {
      min-height: unset;
      color: unset;
    }

    .react-date-picker__wrapper {
      column-gap: $gap-xsmall;
      border: 1px solid $gray-1;
      border-radius: 3px;
      color: $gray-2;
      font-size: 14px;
      padding: 10px 9px 10px 19px;
      min-width: 188px;

      .react-date-picker__inputGroup {
        padding: 0;

        .react-date-picker__inputGroup__month {
          cursor: pointer;
        }

        .react-date-picker__inputGroup__day {
          min-width: 16px;
          text-align: right;
        }
      }

      .react-date-picker__calendar-button {
        transition: $transit-norm;

        .react-date-picker__calendar-button__icon {
          stroke: $gray-2;
        }

        &:hover {
          background-color: unset;
          color: unset;

          .react-date-picker__calendar-button__icon {
            stroke: $gray-2;
          }
        }
      }
    }

    .react-date-picker__calendar {
      .react-calendar__month-view__days__day--weekend {
        color: $red-2;
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        color: $gray-500;
      }

      .react-calendar__tile--active {
        background-color: $primary;
        color: white;
      }

      .react-calendar__navigation__label {
        padding: 0;
      }
    }
  }
}
