@import '../variables.scss';

.tournament-card {
  .teams-list-wrap {
    display: flex;
    flex-direction: column;
  }

  .fa-calendar::before {
    color: $gray-500;
  }
}
