@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('./assets/fonts/fontawesome-webfont.ttf?v=4.7.0');
  font-weight: normal;
  font-style: normal;
}
