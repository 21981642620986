@import '../variables.scss';

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity $transit-norm;
  z-index: $z-modal;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
